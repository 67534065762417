<template>
  <div class="col-md-12 mb-10">
  
    <!-- Header Color Picker -->
 

    <!-- Banner Color Picker -->
     <div class="row">
      <div class="col-lg-6">
          <div class="form-group">
        <label>Change Restaurant Type {{ this.currentUserPersonalInfo.food_type_name  }}</label>
        <b-form-select
          name="type"
          ref="type"
          v-model="food_type"
          :options="allfoodtypes"
          @change="updateFoodType()"
        ></b-form-select>
        <span class="form-text text-muted"
          >What type of food you serving?</span
        >
      </div>
        </div>
     </div>
     <div class="row">
  <div class="col-lg-3 col-sm-12 mb-3">
    <div class="form-group">
      <label for="headerColor">Top Bar Color &nbsp;</label>
      <small v-if="!templateData.pages.home.headerColor" class="text-danger">
         
        <a href="#" @click.prevent="showHeaderColorPicker = true">Add</a>
      </small>

      <div class="d-flex align-items-center">
        <input
          v-if="showHeaderColorPicker"
          type="color"
          id="headerColor"
          class="form-control"
          v-model="templateData.pages.home.headerColor"
          @change="showHeaderColorPicker = true"
        />
        <i
          v-if="showHeaderColorPicker"
          @click="removeColor('headerColor')"
          class="fas fa-trash ml-1 text-danger"
          style="cursor: pointer;"
        ></i>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-sm-12 mb-3">
    <div class="form-group">
      <label for="footerColor">Bottom Bar Color &nbsp;</label>
      <small v-if="!templateData.pages.home.footerColor" class="text-danger">
         
        <a href="#" @click.prevent="showFooterColorPicker = true">Add</a>
      </small>

      <div class="d-flex align-items-center">
        <input
          v-if="showFooterColorPicker"
          type="color"
          id="footerColor"
          class="form-control"
          v-model="templateData.pages.home.footerColor"
          @change="showFooterColorPicker = true"
        />
        <i
          v-if="showFooterColorPicker"
          @click="removeColor('footerColor')"
          class="fas fa-trash ml-1 text-danger"
          style="cursor: pointer;"
        ></i>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-sm-12 mb-3">
    <div class="form-group">
      <label for="buttonsColor">Buttons Color &nbsp;</label>
      <small v-if="!templateData.pages.home.buttonsColor" class="text-danger">
         
        <a href="#" @click.prevent="showbuttonsColorPicker = true">Add</a>
      </small>

      <div class="d-flex align-items-center">
        <input
          v-if="showbuttonsColorPicker"
          type="color"
          id="buttonsColor"
          class="form-control"
          v-model="templateData.pages.home.buttonsColor"
          @change="showbuttonsColorPicker = true"
        />
        <i
          v-if="showbuttonsColorPicker"
          @click="removeColor('buttonsColor')"
          class="fas fa-trash ml-1 text-danger"
          style="cursor: pointer;"
        ></i>
      </div>
    </div>
  </div>

  <div class="col-lg-3 col-sm-12 mb-3">
    <div class="form-group">
      <label for="backgroundColor">Background Color &nbsp;</label>
      <small v-if="!templateData.pages.home.backgroundColor" class="text-danger">
         
        <a href="#" @click.prevent="showbackgroundColorPicker = true">Add</a>
      </small>

      <div class="d-flex align-items-center">
        <input
          v-if="showbackgroundColorPicker"
          type="color"
          id="backgroundColor"
          class="form-control"
          v-model="templateData.pages.home.backgroundColor"
          @change="showbackgroundColorPicker = true"
        />
        <i
          v-if="showbackgroundColorPicker"
          @click="removeColor('backgroundColor')"
          class="fas fa-trash ml-1 text-danger"
          style="cursor: pointer;"
        ></i>
      </div>
    </div>
  </div>
</div>




    <b-tabs v-if="templateData != null" content-class="mt-3">
      <b-tab
        v-for="(section, sectionName) in templateData.pages.home.sections"
        :key="sectionName"
        :title="firstLetterUppercase(sectionName)"
        >
        <div class="row">
          <div class="col-md-4">
            <div v-for="(item, itemName) in section" :key="itemName">
          <div v-if="sectionName == 'banner'" v-for="(slide, slideName) in item">


            <div v-if="slide.type === 'image'">
              <div class="row form-group">
                <div class="col-md-12">
                  <label>{{ slide.label }}</label>
                </div>
                <div class="col-md-7">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    name="banner"
                    v-model="slide.value"
                    @input="uploadImage(sectionName, itemName)"
                  ></b-form-file>
                  <img  v-if="slide.path"  style="width: 400px; height: 200px" :src="baseUrl+slide.path" />
                </div>
              </div>
            </div>
            <div v-else-if="slide.type === 'text'">
              <!-- Render text -->
              <label>{{ slide.label }}</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :placeholder="slide.default"
                v-model="slide.value"
              />
            </div>
          </div>
          <div v-if="sectionName != 'banner'">
            <div v-if="item.type === 'image'">
              <div class="row form-group">
                <div class="col-md-12">
                  <label>{{ item.label }}</label>
                </div>
                <div class="col-md-7">
                  <b-form-file
                    placeholder="Choose a file or drop it here..."
                    drop-placeholder="Drop file here..."
                    name="banner"
                    v-model="item.value"
                    @input="uploadImage(sectionName, itemName)"
                  ></b-form-file> 
                  <img v-if="item.path " style="width: 350px; height: 200px" class="m-2"  :src="baseUrl+item.path" />
                </div>
              </div>
            </div>
            <div v-else-if="item.type === 'text'">
              <!-- Render text -->
              <label>{{ item.label }}</label>
              <input
                type="text"
                class="form-control form-control-solid form-control-lg"
                :placeholder="item.default"
                v-model="item.value"
              />
            </div>
          </div>
          
        </div>
            </div>
        </div>
    
        <!-- <button class="btn btn-primary mt-3 mr-4" @click="saveTemplate(sectionName)">Save</button> -->

        <v-btn  large class="mt-3 btn-primary text-light" @click="saveTemplate(sectionName)">
  Save 
</v-btn>
<v-btn  large class="mt-3 ml-2 text-light  "  style="background-color: red;" @click="resetTheme()">
  Reset To Default
</v-btn>

      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import { GET_CURRENT_TEMPLATE } from "@/core/services/store/profile.module";
import Swal from "sweetalert2";
import ApiService from "@/core/services/api.service";
import { UPDATE_PERSONAL_INFO } from "@/core/services/store/profile.module";

import {
  SAVE_TEMPLATE,
  UPLOAD_TEMPLATE_IMAGES
} from "@/core/services/store/profile.module";
import { mapGetters } from "vuex";

export default {
  props: {
    template: Number
  },
  name: "TemplateSetting",
  computed: {
    ...mapGetters(["currentUser", "currentUserPersonalInfo"])
  },
  data() {
    return {
      showbuttonsColorPicker: false,
      showHeaderColorPicker: false,
      showFooterColorPicker: false,
      showbackgroundColorPicker:false,
      food_type:null,
      allfoodtypes:[],
      temp: null,
      templates: [],
      templateData: null,
      selectedFile: null,
      baseUrl: process.env.VUE_APP_API_BASE_URL
    };
  },
  methods: {
    removeColor(colorKey) {
    // Set the specific color to an empty string
    this.templateData.pages.home[colorKey] = '';
    switch (colorKey) {
      case 'headerColor':
        this.showHeaderColorPicker = false;
        break;
      case 'footerColor':
        this.showFooterColorPicker = false;
        break;
      case 'buttonsColor':
        this.showbuttonsColorPicker = false;
        break;
      case 'backgroundColor':
        this.showbackgroundColorPicker = false;
        break;
      default:
        break;
    }
  },
    getFoodTypes() {
      ApiService.get('business/static/food-types')
      .then(({ data }) => {
        console.log(data.data)
        this.allfoodtypes = data.data;
        this.food_type = this.currentUserPersonalInfo.food_type
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
    },
    firstLetterUppercase(title) {
  if (!title) return ''; // Handle empty string

  return title.charAt(0).toUpperCase() + title.slice(1);
},
    makeToast(variant, message) {
      this.$bvToast.toast(message, {
        title: "Message",
        variant: variant,
        solid: true
      });
    },
  async resetTheme() {
  const { value: confirmed } = await Swal.fire({
    title: "Are you sure?",
    text: "You won't be able to revert this!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, reset it!"
  });

  if (confirmed) {
    try {
      const module = await import(
        `/src/core/config/templates/template${this.currentUserPersonalInfo.template_id}/${this.currentUserPersonalInfo.food_type_name}/template.json`
      );
      
      this.templateData = module.default;
     
      this.intitilizeColors()

      await this.saveTemplate();
    } catch (error) {
      console.error(
        'Error importing template or saving template:',
        error
      );
    }
  }
},
   async saveTemplate(sectionName) {
  //  await this.updateFoodType()
      console.log('this.food_type',this.food_type)
      if (this.currentUser != "") {
        console.log("this.template", this.templateData);
        const form = {
          templateData: this.templateData,
          business: this.currentUser.id,
          template: this.template,
         // food_type_id:this.food_type
        };

        this.$store.dispatch(SAVE_TEMPLATE, form).then(data => {
          this.templateData = data.data;
          this.makeToast("success", "Business Template updated successfully");
        });
        let personalInfo = JSON.parse(sessionStorage.getItem('personalInfo'));
        personalInfo.template_id = this.template;
        sessionStorage.setItem('personalInfo', JSON.stringify(personalInfo));
        this.$store.dispatch(UPDATE_PERSONAL_INFO)

      }
    },
    updateFoodType(){


      let currentUserId = this.currentUser.id;
      ApiService.post('business/settings/wizard/store/restaurant-type/'+currentUserId, {
        food_type:this.food_type
      })
      .then(({ data }) => {
          sessionStorage.removeItem("personalInfo");
          this.$store.dispatch(UPDATE_PERSONAL_INFO);
          this.loadTemplateData(this.template);
        return true;
      })
    },
    handleFileChange(event) {
      console.log(event.target.files[0]);
      this.templateData["pages"]["home"]["sections"]["banner"]["slide_1"][
        "image"
      ]["value"] = event.target.files[0];
    },
  
    uploadImage(sectionName, itemName) {
  if (sectionName === "banner") {
    this.selectedFile = this.templateData.pages.home.sections[sectionName][itemName].image.value;
  } else {
    this.selectedFile = this.templateData.pages.home.sections[sectionName][itemName].value;
  }

  const formData = new FormData();
  formData.append("image", this.selectedFile);
  formData.append("image_key", itemName);
  formData.append("image_section", sectionName);
  formData.append("template_id", this.template);
  formData.append("user_id", this.currentUser.id);

  const file = this.selectedFile;

  // Conditional check for specific sections
  if (
    sectionName === "promotion" ||
    sectionName === "sub_section_1" ||
    sectionName === "sub_section_2"
  ) {
    if (file) {
      const maxSize = 5 * 1024 * 1024; // 5MB
      const minWidth = 200; // Minimum width: 200px
      const minHeight = 200; // Minimum height: 300px
      const maxWidth = 500; // Maximum width: 500px
      const maxHeight = 750; // Maximum height: 750px

      // Check file size
      if (file.size > maxSize) {
        this.makeToast("danger", "File size exceeds the maximum limit of 5MB.");
        return;
      }

      const img = new Image();
      img.onload = () => {
        const width = img.width;
        const height = img.height;

        // Check minimum dimensions
        if (width < minWidth || height < minHeight) {
          this.makeToast(
            "danger",
            `Image dimensions are too small. Minimum dimensions are ${minWidth}x${minHeight} pixels.`
          );
          return;
        }

        // Check maximum dimensions
        if (width > maxWidth || height > maxHeight) {
          this.makeToast(
            "danger",
            `Image dimensions are too large. Maximum dimensions are ${maxWidth}x${maxHeight} pixels.`
          );
          return;
        }

        // Proceed with the upload if checks pass
        this.$store.dispatch(UPLOAD_TEMPLATE_IMAGES, formData).then((data) => {
          if (sectionName === "banner") {
            this.templateData.pages.home.sections[sectionName][itemName].image.path = data;
          } else {
            this.templateData.pages.home.sections[sectionName][itemName].path = data;
          }
        });
      };

      img.onerror = () => {
        this.makeToast("danger", "Invalid image file.");
      };

      img.src = URL.createObjectURL(file);
    }
  } else {
    // If no dimension or size checks are needed, proceed to upload
    this.$store.dispatch(UPLOAD_TEMPLATE_IMAGES, formData).then((data) => {
      if (sectionName === "banner") {
        this.templateData.pages.home.sections[sectionName][itemName].image.path = data;
      } else {
        this.templateData.pages.home.sections[sectionName][itemName].path = data;
      }
    });
  }
},

    intitilizeColors(){
      this.showbuttonsColorPicker = !!this.templateData.pages.home.buttonsColor;
             this.showHeaderColorPicker = !!this.templateData.pages.home.headerColor;
      this.showFooterColorPicker = !!this.templateData.pages.home.footerColor;
      this.showbackgroundColorPicker = !!this.templateData.pages.home.backgroundColor;

    },
    loadTemplateData(template_id) {
      

      this.$store
        .dispatch(GET_CURRENT_TEMPLATE, { template_id: template_id })
        .then(result => {
          
          if (result.data.template_data) {
            this.templateData = result.data.template_data;
            console.log('templateData',this.templateData)
              this.intitilizeColors()
          } else {
                import(
                  "/src/core/config/templates/template" +
                  template_id +
                  "/" +
                  this.currentUserPersonalInfo.food_type_name +
                  "/template.json"
              )
                .then(module => {
                  this.templateData = module.default;
                  this.intitilizeColors()


                })
                .catch(error => {
                  console.error("Error loading JSON file:", error);
                });
            
          }
        });
    }
  },
  mounted() {
    this.loadTemplateData(this.template);
    this.getFoodTypes()
  },
  watch: {
    template(newVal, oldVal) {
      this.loadTemplateData(newVal);
    }
  }
};
</script>
<style scoped>

.border-danger {
  border-color: red !important; /* Change to your preferred error color */
}
.text-danger {
  color: red; /* Error message color */
}
</style>