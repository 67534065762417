<template>
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <!--end::Header-->
    <div class="row card-body pt-10">
      <div class="col-md-12">
        <div class="row">
          <div class="col-12">
            <span class="theme-card-title font-poppins">Select theme to load configuration</span>
          </div>

          <!-- Flex container for theme cards -->
          <div class="col-12">
            <div class="theme-cards-container">
              <div
                v-for="theme in filteredThemes"
                :key="theme.value"
                class="theme-card-wrapper"
              >
                <div
                  class="theme-card"
                  :class="{ 'theme-card-selected': theme.value === themeId }"
                  @click="selectTheme(theme)"
                >
                  <img :src="theme.image" class="theme-card-image" />
                  <div class="card-label font-weight-bolder text-dark font-poppins pt-1">
                    {{ theme.text }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row">
          <TemplateSettings v-if="theme" :template="theme" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { GET_TEMPLATES, GET_CURRENT_TEMPLATE } from "@/core/services/store/profile.module";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Template1 from "@/view/pages/templates/Template1";
import TemplateSettings from "@/view/pages/business/components/settings/TemplateSettings";
import { mapGetters } from "vuex";

export default {
  name: "Setting",
  components: {
    Template1,
    TemplateSettings
  },
  computed: {
    ...mapGetters(["currentUser", "currentUserPersonalInfo"]),
    filteredThemes() {
      return this.themes.filter(theme => theme.text !== null);
    }
  },
  data() {
    return {
      themes: [
        { text: null, value: "Select your theme", image: "" }
      ],
      theme: null,
      themeId: null // Track the selected theme ID
    };
  },
  methods: {
    selectTheme(theme) {
      this.themeId = theme.value;
      this.theme = theme.value; // Update theme object
    }
  },
  mounted() {
    this.theme = parseInt(this.currentUserPersonalInfo.template_id);
    this.themeId = this.theme;
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Business Settings" },
      { title: "Themes" }
    ]);

    this.$store.dispatch(GET_TEMPLATES).then((result) => {
      result.forEach((v) => {
        this.themes.push({
          value: v.id,
          text: v.template_name,
          image: v.image
        });
      });
    });
  }
};
</script>

<style lang="scss">
@import "@/assets/sass/pages/wizard/wizard-1.scss";

// Flex container for theme cards
.theme-cards-container {
  display: flex;
  flex-wrap: nowrap; /* Prevent wrapping */
  overflow-x: auto; /* Allow horizontal scrolling */
  padding-bottom: 10px; /* Add space at the bottom if needed */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #4d4a9e #cbcbdc; /* Firefox */
}

/* Webkit-based browsers (Chrome, Safari) */
.theme-cards-container::-webkit-scrollbar {
  height: 8px; /* Vertical scrollbar height */
  width: 8px; /* Horizontal scrollbar width */
}

.theme-cards-container::-webkit-scrollbar-track {
  background: #f0fdf4; /* Track color */
  border-radius: 10px;
}

.theme-cards-container::-webkit-scrollbar-thumb {
  background-color: #4d4a9e; /* Scrollbar thumb color */
  border-radius: 10px;
  border: 2px solid #f0fdf4; /* Adds some space around the thumb */
}

.theme-card-wrapper {
  flex: 0 0 auto; /* Prevent cards from stretching */
  margin-right: 15px; /* Add some space between cards */
}

// Styling for individual theme cards
.theme-card {
  border: 2px solid transparent;
  border-radius: 12px;
  padding: 20px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  margin-bottom: 10px; /* Reduced bottom margin */
}

.theme-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
}

.theme-card-selected {
  border-color: #4CAF50;
  background-color: #f0fdf4;
  box-shadow: 0 6px 20px rgba(76, 175, 80, 0.2);
}

.theme-card-image {
  max-width: 100%;
  height: 200px;
  border-radius: 8px;
  margin-bottom: 15px;
  transition: transform 0.3s;
}

.theme-card:hover .theme-card-image {
  transform: scale(1.05);
}

.theme-card-title {
  font-size: 20px;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
}
</style>
